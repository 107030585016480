import {
  meilisearchEvents,
  meilisearchEventType,
  onSelectedCurrentLocation
} from "@/modules/meilisearch/features/meilisearch.events";
import {keyupHandlerMeiliSearch, TypeSearch} from "@/modules/meilisearch/meilisearch.core";
import {initSelectControl} from "@/modules/meilisearch/features/meilisearch.key-control";
import {onLocationSelected, onTypeSelected} from "@/pages/home/helpers";

export const meilisearchInit = () => {
  window.meilisearchEvents = (
    event: meilisearchEventType,
    idElement: string
  ) => meilisearchEvents(event, idElement)

  /** When click selected on location */
  window.onLocationSelected = (
    locationObj,
    idElement,
    redirectProject,
    redirectLocation
  ) => onLocationSelected(locationObj, idElement, redirectProject, redirectLocation)

  /** Click on type place */
  window.onTypeSelected = (
    idElement: string,
    place: TypeSearch,
    redirectProject,
    redirectLocation
  ) => onTypeSelected(idElement, place, redirectProject, redirectLocation)
  window.onSelectedCurrentLocation = () => onSelectedCurrentLocation()

  /** When typing on input search */
  window.keyupHandlerMeiliSearch = async (
    id: string,
    redirectProject,
    redirectLocation,
    type,
  ) => keyupHandlerMeiliSearch(id, redirectProject, redirectLocation, type)

  window.initSelectControl = (
    idElement: string,
    e: KeyboardEvent
  ) => initSelectControl(idElement, e)
}